import darkTheme from './darkTheme';
import lightTheme from './lightTheme';

const THEMES = {
  dark: darkTheme,
  light: lightTheme
};

const DEFAULT_THEME = 'light';

export const setThemeName = (themeName) => {
  window.localStorage.setItem('activeTheme', themeName);
};

const getActiveThemeFromStorage = () => {
  return window.localStorage.getItem('activeTheme');
};

export const getThemeName = () => {
  if (typeof window !== 'undefined') {
    const activeThemeName = getActiveThemeFromStorage();

    if (!activeThemeName) {
      setThemeName(DEFAULT_THEME);
      return DEFAULT_THEME;
    }

    return activeThemeName;
  }

  return DEFAULT_THEME;
};

export const getTheme = (themeName) => {
  return THEMES[themeName];
};

export const toggleTheme = () => {
  const themeName = getThemeName() === 'dark' ? 'light' : 'dark';

  window.localStorage.setItem('activeTheme', themeName);

  return themeName;
};

export { darkTheme, lightTheme };
