import React, { createContext } from 'react';
import { getThemeName, getTheme, setThemeName, toggleTheme } from 'theme';

export const ThemeContext = createContext({
  toggleTheme,
  getThemeName,
  getTheme
});

const storageThemeEffect = (initialThemeName, callback) => {
  const [name, setLocalThemeName] = React.useState(initialThemeName);

  React.useEffect(() => {
    setName(getThemeName());
  }, []);

  const setName = (name) => {
    setThemeName(name);
    setLocalThemeName(name);
  };

  const toggle = () => {
    const newThemeName = toggleTheme();
    setName(newThemeName);

    const updatedTheme = getTheme(newThemeName);
    callback(updatedTheme);
  };

  return { name, toggle };
};

export function ToggleThemeProvider({ children, callback }) {
  const localStorageThemeEffect = storageThemeEffect('light', callback);

  return <ThemeContext.Provider value={localStorageThemeEffect}>{children}</ThemeContext.Provider>;
}
