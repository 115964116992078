import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';
import { isLoggedIn, showLoginIfNeeded } from 'utils/loginService';
import { useRouter } from 'next/router';

const useClasses = makeStyles((theme) => ({
  skeletonRoot: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  },
  placeholder: {
    margin: '0 auto',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: theme.spacing(5),
    padding: theme.spacing(5)
  }
}));

const allowedPaths = ['/login', '/forgot-password'];

function Skeleton({ children }) {
  const classes = useClasses();
  const router = useRouter();
  const showPlaceholder = !allowedPaths.includes(router.pathname) && !isLoggedIn();

  showLoginIfNeeded(!allowedPaths.includes(router.pathname));

  return (
    <div>
      {!!showPlaceholder && (
        <div key="skeleton-container" className={classes.skeletonRoot}>
          <div className={classes.placeholder}>
            <ContentLoader
              uniqueKey="skeleton-loader"
              speed={1}
              width={350}
              height={450}
              viewBox="0 0 300 300"
              backgroundColor="#fff"
              backgroundOpacity={0.15}
              foregroundColor="#fff"
              foregroundOpacity={0.75}
            >
              <circle cx="30" cy="20" r="8" />
              <rect x="45" y="15" rx="5" ry="5" width="220" height="10" />
              <circle cx="30" cy="50" r="8" />
              <rect x="45" y="45" rx="5" ry="5" width="220" height="10" />
              <circle cx="30" cy="80" r="8" />
              <rect x="45" y="75" rx="5" ry="5" width="220" height="10" />
              <circle cx="30" cy="110" r="8" />
              <rect x="45" y="105" rx="5" ry="5" width="220" height="10" />
              <rect x="30" y="141" rx="0" ry="0" width="237" height="296" />
            </ContentLoader>
          </div>
        </div>
      )}
      {!showPlaceholder && children}
    </div>
  );
}

Skeleton.defaultProps = {};

export default Skeleton;
